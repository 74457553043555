
























import {
  computed,
  defineComponent,
  onMounted
} from '@nuxtjs/composition-api'
import { SfButton, SfBadge } from '@storefront-ui/vue'
import { useWishlist, useWishlistStore } from '@wemade-vsf/wishlist';

export default defineComponent({
  name: 'WishlistIcon',
  components: {
    SfBadge,
    SfButton
  },
  props: {
    iconHeight: {
      type: String,
      default: '1.6875rem'
    },
    iconWidth: {
      type: String,
      default: '1.6875rem'
    }
  },
  setup () {
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    onMounted(async () => {
      await loadWishlistItemsCount()
    })

    return {
      wishlistItemsQty,
      wishlistHasProducts
    }
  }
})
